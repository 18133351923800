.App {
  padding: 1em 2em;
}
.App img {
  max-width: 100%;
}

.balances {
  display: flex;
  flex-wrap: wrap;
}
.balances > * {
  margin-right: 1em;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  max-width: 100vw;
  overflow-x: scroll;
}
.balances > *:last-child {
  border-right: none;
  margin-right: 0;
}

.balances td {
  vertical-align: text-top;
}
.asset-table {
  display: block;
  margin: 0.5em;
  max-height: 200px;
  overflow-y: scroll;
}
.asset-table td {
  font-family: monospace;
  padding: 0.1em;
}

button {
  margin: 1em;
}
